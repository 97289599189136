import React, { useState } from "react";
import { Grid, Card, Typography, Button, TextField } from "@mui/material";

import { Formik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";

import { H4, Paragraph } from "../../components/Typography";

import { currency } from "../../utils/lib";

const initialValues = {
  id: "",
};

export default function Bubble({ item, setAmount }) {
  const [open, setOpen] = useState(false);

  const handleFieldValueChange = (value, fieldName, setFieldValue) => () => {
    setFieldValue(fieldName, value);
    setAmount(value);
  };

  return (
    <Formik initialValues={initialValues}>
      {({ values, handleSubmit, setFieldValue }) => (
        <Grid mt={3} container spacing={3}>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              id=""
              label="Enter Amount"
              type="number"
              onChange={(e) =>
                setAmount(
                  parseFloat(e.target.value) / parseFloat(item?.fx.rate)
                )
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">HTG</InputAdornment>
                ),
              }}
              inputProps={{
                autoFocus: true,
                min: 100,
                max: 10000,
              }}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Button variant="outlined" onClick={() => setOpen(!open)}>
              {open ? "Hide Suggestion" : "Show Suggestions"}
            </Button>
          </Grid>

          {open
            ? item.suggestedAmounts?.map((v) => (
                <Grid mt={1} item md={2} xs={6}>
                  <Card
                    sx={{
                      padding: 1,
                      boxShadow: "none",
                      cursor: "pointer",
                      border: "1px solid",
                      position: "relative",
                      backgroundColor: "grey.100",
                      width: "100%",
                      borderColor:
                        v === values.id ? "primary.main" : "transparent",
                    }}
                    onClick={handleFieldValueChange(v, "id", setFieldValue)}
                  >
                    {/**
                <H4 mb={0.5}>{}</H4>
                <Paragraph color="grey.700">
                  {item?.type_of_org?.activity}
                </Paragraph>
                 */}
                    <Grid
                      container
                      spacing={1}
                      my={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                    >
                      <Typography variant="h6" color="initial">
                        {currency(Math.floor(v), 2, "USD")}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                    >
                      <Typography variant="body1" color="initial">
                        ({currency(Math.floor(v * item?.fx.rate), 2, "HTG")})
                      </Typography>
                    </Grid>
                  </Card>
                </Grid>
              ))
            : null}
        </Grid>
      )}
    </Formik>
  );
}
