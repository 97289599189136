import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { postData } from "../fetch/post";

export default function formAddAccount({ id }) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState("");

  const handleSent = async () => {
    if (name && number) {
      const res = await postData(`${process.env.REACT_APP_ACCOUNT}/edit_withdraw/`, {
        name,
        number,
        flag,
        id
      });
      if (res.message === "success") {
        toast(res.message);
        window.location.reload();
        return;
      } else {
        toast(res.message);
        return;
      }
    }
    toast("required (name && number && flag)");
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <TextField
          label="Name Account"
          fullWidth
          placeholder="ex : SogeBank"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          label="Number Account"
          fullWidth
          placeholder="ex: 323444333"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setFlag(e.target.value)}
          select
          fullWidth
          label="flag"
          value={flag}
        >
          <MenuItem value="">....</MenuItem>
          <MenuItem value="https://www.sogebank.com/wp-content/uploads/2017/12/sogebank.png">
            SogeBank
          </MenuItem>
          <MenuItem value="https://play-lh.googleusercontent.com/4H2rEreKNnL_axWHZ8VEgIT4VHK0g0fN30Q96V56ESgULp5-wbIbyg_aZhqCwazIaPhV=w240-h480-rw">
            Moncash
          </MenuItem>
          <MenuItem value="https://static.wixstatic.com/media/1f0c3c_c4ca7277a6614994aa449d080f200c1e~mv2.png/v1/fill/w_280,h_262,al_c,lg_1,q_85,enc_auto/unibank-logo.png">
            Unibank
          </MenuItem>
        </TextField>
      </Grid>

      <Grid item md={12} xs={12}>
        <Button onClick={handleSent} fullWidth variant="outlined">
          Edit
        </Button>
      </Grid>
    </Grid>
  );
}
