import React from "react";
import { Layout } from "../layouts/dashboard/layout";
import { SettingsNotifications } from "../pages-sections/settings/settings-notifications";

export default function SettingsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <SettingsNotifications />
    </Layout>
  );
}
