import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "../layouts/dashboard/layout";
import Index from "../pages-sections/dashboard";

export default function App() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!user.is_email_check) navigate("/user/verify");
  }, [user, navigate]);

  return (
    <Layout>
      <Index />
    </Layout>
  );
}
