import { subDays, subHours } from "date-fns";
import { Box, Button, Container, Unstable_Grid2 as Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { OverviewBudget } from "../overview/overview-budget";
import { OverviewLatestOrders } from "../overview/overview-latest-orders";
import { OverviewLatestProducts } from "../overview/overview-latest-products";
import { OverviewSales } from "../overview/overview-sales";
import { OverviewTraffic } from "../overview/overview-traffic";
import HorizontalScrollCardGrid from "../../components/horizontalScrollCardGrid";
import Analytics from "../../components/analyse";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  //SwapHorizOutlined,
} from "@mui/icons-material";
import { useGet } from "../../hooks/useGet";
import { usePost } from "../../hooks/usePost";

const now = new Date().toISOString().split("T")[0];

// Calculate the date one year ago
var oneYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);

// Convert the date to an ISO string and extract the date part
var oneYearAgoString = oneYearAgo.toISOString().split("T")[0];

// Output the date one year ago
console.log("One year ago: " + oneYearAgoString);

const Page = () => {
  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_TRANSACTION}/user/`
  );

  const [account, load, error3] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get/`
  );

  const [data2, loading2, error2] = useGet(`${process.env.REACT_APP_USER}/get_friend_account/`);

  const [analyse, error4] = usePost(
    `${process.env.REACT_APP_ANALYSE}/bank_reports/`,
    {
      startDate: oneYearAgo,
      endDate: now,
    },
    []
  );
  const [traffic, load2, error5] = useGet(
    `${process.env.REACT_APP_ANALYSE}/split/`
  );

  if (error) {
    return <h3>Error to get transaction</h3>;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Button
                LinkComponent={Link}
                to="/transaction/add"
                variant="outlined"
                sx={{
                  marginRight: "10px",
                  padding: { md: "5px, 32px", xs: "5px 15px" },
                }}
                startIcon={<Add />}
              >
                Add Money
              </Button>
              <Button
                LinkComponent={Link}
                to="/transaction/convert"
                variant="outlined"
                sx={{
                  marginRight: "10px",
                  padding: { md: "5px, 32px", xs: "5px 15px" },
                }}
                startIcon={<ArrowUpward />}
              >
                Convert
              </Button>
              <Button
                LinkComponent={Link}
                to="/transaction/withdraw"
                variant="outlined"
                sx={{
                  marginRight: "10px",
                  padding: { md: "5px, 32px", xs: "5px 15px" },
                }}
                startIcon={<ArrowDownward />}
              >
                WithDraw
              </Button>
            </Grid>

            <HorizontalScrollCardGrid
              data={account}
              loading={load}
              CardItem={OverviewBudget}
            />

            <Grid xs={12} lg={8}>
              <Analytics details={analyse} />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {traffic ? (
                <OverviewTraffic
                  chartSeries={[traffic.debit, traffic.credit]}
                  labels={["Debit", "Credit"]}
                  sx={{ height: "100%" }}
                />
              ) : (
                "Loading"
              )}
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <OverviewLatestProducts
                products={data2 ? data2 : []}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={12} md={12} lg={8}>
              <OverviewLatestOrders
                orders={data ? data.data : []}
                sx={{ height: "100%" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Page;
