import React, { useState } from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

import AutoCompletion from "../../components/autoComplete";
import Dialog from "../../components/dialog";
import OTPForm from "../account/user/verify";
import { currency } from "../../utils/lib";
import { toast } from "react-toastify";

export default function VerifyTopup({
  account,
  accounts,
  setAccount,
  accountRecieve,
  amount,
  rate,
  handleBack,
  setOTP,
  sendOTPToAPI,
  sent,
  open,
  setOpen,
}) {
  const message = "Verify with OTP";
  const handleClose = () => {
    setOpen(!open);
  };

  const fees = parseFloat(amount * 0.1).toFixed(2);

  const handleChangeAccount = (value) => {
    setAccount(value);
  };

  const handleSubmit = () => {
    if (account.currency?.code === "HTG") {
      if (
        parseFloat(amount * rate) + parseFloat(fees * rate) >
        parseFloat(account.amount)
      ) {
        toast("Insufficient Funds");
        return;
      }
      handleClose();
    }
    if (account.currency?.code === "USD") {
      if (parseFloat(amount) + parseFloat(fees) > parseFloat(account.amount)) {
        toast("Insufficient Funds");
        return;
      }
      handleClose();
    }
  };

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Dialog
        Content={
          <OTPForm
            open={sent}
            sendOTPToAPI={sendOTPToAPI}
            setOTPPass={setOTP}
          />
        }
        open={open}
        handleClose={handleClose}
        message={message}
      />

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        sx={{ padding: "20px", backgroundColor: "#f4f4f4" }}
      >
        <Typography
          align="center"
          color="text.secondary"
          sx={{
            lineHeight: "32px",
          }}
          variant="p"
        >
          Please verify your transaction before confirming it
        </Typography>

        {/* Account Number Sender */}
        <Grid
          container
          mt={5}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Account Sender
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            {/** 
            <Typography variant="body1" textAlign="end" color="primary">
              {account.number} - {account.amount} {account.currency?.code}
            </Typography>
        */}
            <AutoCompletion
              placeholder="Select Account Speedy"
              value={account}
              handleChange={(value) => handleChangeAccount(value)}
              data={accounts}
              show={true}
            />
          </Grid>
        </Grid>

        {/* Account Number Recieve */}
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Account Recieve
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {accountRecieve}
            </Typography>
          </Grid>
        </Grid>

        {/* Amount */}
        <Grid
          container
          mt={0}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Amount
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {account.currency?.code === "HTG"
                ? currency(amount * rate, 2, account.currency?.code)
                : currency(amount, 2, account.currency?.code)}
            </Typography>
          </Grid>
        </Grid>

        {/* Amount */}

        {/* Fee */}
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Fees
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {account.currency?.code === "HTG"
                ? currency(
                    parseFloat(fees * rate).toFixed(2),
                    2,
                    account.currency?.code
                  )
                : currency(
                    parseFloat(fees).toFixed(2),
                    2,
                    account.currency?.code
                  )}
            </Typography>
          </Grid>
        </Grid>

        {/* Total */}

        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Amount Total
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {account.currency?.code === "HTG"
                ? currency(
                    (parseFloat(amount) + parseFloat(fees)) * rate,

                    2,
                    account.currency?.code
                  )
                : currency(
                    parseFloat(amount) + parseFloat(fees),
                    2,
                    account.currency?.code
                  )}
            </Typography>
          </Grid>
        </Grid>

        {/* Verify Button */}
        <Grid item mt={5} md={12} xs={12}>
          <Button
            onClick={handleSubmit}
            sx={{ float: "right", marginLeft: "16px" }}
            variant="outlined"
          >
            Verify
          </Button>
          <Button
            onClick={() => handleBack()}
            sx={{ float: "right" }}
            variant="outlined"
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
