import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

import { Formik } from "formik";

import { H4, Paragraph } from "../../components/Typography";

import Bubble from "./Bubble";
import Amount from "./Amount";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const initialValues = {
  item: "",
};

export default function SelectOption({
  operators,
  phone,
  setPhone,
  setOperatorId,
  setAmount,
  setRate,
  handleReview,
}) {

  
  const handleFieldValueChange = (value, fieldName, setFieldValue) => () => {
    setOperatorId(value.id);
    setRate(value.fx.rate)
    setFieldValue(fieldName, value);
  };

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Formik initialValues={initialValues}>
        {({ values, handleSubmit, setFieldValue }) => (
          <Grid container spacing={3} sx={{ padding: "20%, 10%" }}>
            <Grid item md={12}>
              <Typography
                color="text.secondary"
                sx={{
                  lineHeight: "32px",
                }}
                variant="h4"
              >
                Send Top-up
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography
                color="text.secondary"
                sx={{
                  lineHeight: "32px",
                }}
                variant="p"
              >
                Enter the number that you would like to recharge
              </Typography>
            </Grid>
            <Grid item mt={1} md={12} xs={12}>
              <PhoneInput
                country={"ht"}
                onlyCountries={["ht"]}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputProps={{
                  name: "number",
                  required: true,
                  autoFocus: true,
                }}
              />
            </Grid>
            {phone.length >= 11
              ? operators.map((item) => (
                  <Grid mt={1} item md={3} xs={12}>
                    <Card
                      sx={{
                        padding: 1,
                        boxShadow: "none",
                        cursor: "pointer",
                        border: "1px solid",
                        position: "relative",
                        backgroundColor: "grey.100",
                        width: "100%",
                        height: "130px",
                        borderColor:
                          item === values.item ? "primary.main" : "transparent",
                      }}
                      onClick={handleFieldValueChange(
                        item,
                        "item",
                        setFieldValue
                      )}
                    >
                      <H4 mb={0.5}>{item.name}</H4>
                      <Paragraph color="grey.700">
                        {item?.type_of_org?.activity}
                      </Paragraph>
                      <Grid
                        container
                        spacing={1}
                        m={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                      >
                        <img src={`${item.logoUrls[1]}`} />
                      </Grid>
                    </Card>
                  </Grid>
                ))
              : null}
            {values.item?.suggestedAmounts?.length >= 1 &&
            phone.length >= 11 ? (
              <Grid item md={12}>
                <Grid item md={12}>
                  <Typography
                    color="text.secondary"
                    sx={{
                      lineHeight: "32px",
                    }}
                    variant="p"
                  >
                    TOP-UP
                  </Typography>
                </Grid>
                <Amount setAmount={setAmount} item={values.item} />
              </Grid>
            ) : (
              <Grid item md={12}>
                <Grid item md={12}>
                  <Typography
                    color="text.secondary"
                    sx={{
                      lineHeight: "32px",
                    }}
                    variant="p"
                  >
                    DATA PLAN
                  </Typography>
                </Grid>
                {values.item ? (
                  <Bubble setAmount={setAmount} item={values.item} />
                ) : null}
              </Grid>
            )}
            <Grid item md={12}>
              <Button
                onClick={handleReview}
                variant="outlined"
                sx={{ float: "right" }}
              >
                Verify and Pay
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Card>
  );
}
