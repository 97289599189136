import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Layout } from "../../layouts/dashboard/layout";
import Sent from "../../pages-sections/transaction/sent/index";
import Verify from "../../pages-sections/transaction/convert/verify";
import Complete from "../../pages-sections/transaction/convert/complete";

import Stepper from "../../components/stepper";

import { useGet } from "../../hooks/useGet";
import { postData } from "../../fetch/post";
import { usePost } from "../../hooks/usePost";

const steps = [
  "Choose Account to convert devis",
  "Verify information",
  "Complete",
];

export default function ConvertPage() {
  const { id } = useParams();

  const [account, setAccount] = React.useState(null);
  const [accountRecieve, setAccountRecieve] = useState(null);
  const [amount, setAmount] = React.useState(1500);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [otp, setOTP] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // for otp modal
  const [sent, setSent] = React.useState(false);

  // get all account
  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get/`
  );

  const [listAccount, error2] = usePost(
    `${process.env.REACT_APP_ACCOUNT}/by_contact/`,{
        id
    },[]
  );

  // Function to handle sending OTP to API
  const sendOTPToAPI = async () => {
    setSent(true);
    // Send OTP to API
    console.log("Sending OTP to API:", otp);
    // You can use fetch or any other method to send OTP to the API
    const res = await postData(`${process.env.REACT_APP_USER}/verify_otp/`, {
      pin: otp,
    });
    if (res.message === "success") {
      setOpen(!open);
      setSent(false);
      handleSentData();
      return;
    } else {
      toast(res.message);
      setSent(false);
    }
    setSent(false);
  };

  if (loading) {
    return <h1>Loading ...</h1>;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    if (!account) {
      toast("Select account");
      return;
    }
    if (!accountRecieve) {
      toast("Select accountRecieve");
      return;
    }
    if (account?.currency.code === "USD") {
      if (amount < 3) {
        toast("Amount should 3 or more");
        return;
      }
    }
    if (account?.currency.code === "HTG") {
      if (amount < 50) {
        toast("Amount should 50 or more");
        return;
      }
    }

    if (Number(account.amount) < Number(amount)) {
      toast("Not enough funds !");
      return;
    }

    const isLastStep = () => {
      return activeStep === steps.length - 1;
    };

    const allStepsCompleted = () => {
      return Object.keys(completed).length === steps.length;
    };

    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  /** 
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  */

  const handleSentData = async () => {
    const res = await postData(`${process.env.REACT_APP_ACCOUNT}/transfert/`, {
      account_sender_id: account.id,
      account_recieve_id: accountRecieve.id,
      amount: amount,
      pin: otp,
    });
    if (res.message === "success") {
      handleComplete();
      return;
    } else {
      toast(res.message);
      return null;
    }
  };

  return (
    <Layout>
      <Box>
        <Grid
          container
          mt={5}
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Stepper
            steps={steps}
            activeStep={activeStep}
            completed={completed}
          />
        </Grid>

        <h3 style={{ marginLeft: "3%" }}>Sent devis</h3>
        {activeStep === 0 && data ? (
          <Sent
            data={data}
            listAccount={listAccount}
            amount={amount}
            accountRecieve={accountRecieve}
            setAccountRecieve={setAccountRecieve}
            setAccount={setAccount}
            account={account}
            setAmount={setAmount}
            handleComplete={handleComplete}
          />
        ) : null}
        {activeStep === 1 && data ? (
          <Verify
            amount={amount}
            account={account}
            accountRecieve={accountRecieve}
            fee={40.0}
            handleBack={handleBack}
            setOTP={setOTP}
            sent={sent}
            sendOTPToAPI={sendOTPToAPI}
            open={open}
            setOpen={setOpen}
          />
        ) : null}
        {activeStep === 2 && data ? <Complete data={data} /> : null}
      </Box>
    </Layout>
  );
}
