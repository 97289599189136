import React, { useState, useRef, useEffect } from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

import { Formik } from "formik";

import { H4, Paragraph } from "../../components/Typography";

import { currency } from "../../utils/lib";

const initialValues = {
  id: "",
};

export default function Bubble({ item, setAmount }) {
  const handleFieldValueChange = (value, fieldName, setFieldValue) => () => {
    setFieldValue(fieldName, value);
    setAmount(value);
  };

  const divRef = useRef(null);

  const focusDiv = () => {
    if (divRef.current) {
      divRef.current.focus();
    }
  };

  useEffect(() => {
    // Optionally, focus on the div when the component mounts
    focusDiv();
  }, []);

  return (
    <Formik initialValues={initialValues}>
      {({ values, handleSubmit, setFieldValue }) => (
        <Grid ref={divRef} tabIndex="0" container spacing={3}>
          {Object.entries(item?.fixedAmountsDescriptions).map(([key, val]) => (
            <Grid mt={1} item md={4} xs={12}>
              <Card
                sx={{
                  padding: 1,
                  boxShadow: "none",
                  cursor: "pointer",
                  border: "1px solid",
                  position: "relative",
                  p: 5,
                  backgroundColor: "grey.100",
                  width: "100%",
                  height: "250px",
                  borderColor:
                    key === values.id ? "primary.main" : "transparent",
                }}
                onClick={handleFieldValueChange(key, "id", setFieldValue)}
              >
                <H4 mb={0.5}>{String(val).substring(0, 30)}</H4>
                <Paragraph color="grey.700">{val}</Paragraph>
                <Grid
                  container
                  spacing={1}
                  my={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                >
                  <Typography variant="h6" color="initial">
                    {currency(Math.floor(key), 2, "USD")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                >
                  <Typography variant="body1" color="initial">
                    ({currency(Math.floor(key * item?.fx.rate), 2, "HTG")})
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Formik>
  );
}
