import React from "react";
import LoginForm from "../pages-sections/login";
import { Layout } from "../layouts/auth/layout";

export default function Login() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
}
