import { Grid, Typography, Container, Paper } from "@mui/material";

import ContactForm from "../../components/contactForm";

export default function contact() {
  return (
    <Grid sx={{ marginTop: "50px" }} container spacing={0}>
      <Paper elevation={1}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          p={5}
        >
          <Grid item pt={5} pb={3}>
            <Typography variant="h4" color="initial">
              Contactez-nous
            </Typography>
          </Grid>
          <Grid item pt={0} pb={5}>
            <Typography alignItems="center" variant="p" color="initial">
              Vous souhaitez nous contacter? Vous pouvez soit nous envoyer un
              courriel à hello@speedytransfert.com ou utiliser le numéro
              +50947772293 .
            </Typography>
          </Grid>

          <ContactForm />
        </Grid>
      </Paper>
    </Grid>
  );
}
