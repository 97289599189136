// src/components/HowItWorks.js
import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const steps = [
  {
    icon: <AccountCircleIcon style={{ fontSize: 50, color: '#3f51b5' }} />,
    title: 'Créer un compte',
    description: 'Inscrivez-vous facilement avec votre adresse email.'
  },
  {
    icon: <AccountBalanceWalletIcon style={{ fontSize: 50, color: '#ff5722' }} />,
    title: 'Ajouter des fonds à votre wallet Speedy',
    description: 'Sécurisé et rapide.'
  },
  {
    icon: <SwapHorizIcon style={{ fontSize: 50, color: '#4caf50' }} />,
    title: 'Effectuer des transferts',
    description: 'Transférez de l\'argent ou achetez des cartes cadeaux en quelques clics.'
  }
];

function HowItWorks() {
  return (
    <Box id="faq" sx={{ pt: 0, pb: 6 }}>
      <Typography variant="h4" mb={5} component="h2" gutterBottom align="center">
        Comment ça marche
      </Typography>
      <Grid container spacing={4}>
        {steps.map((step, index) => (
          <Grid item key={index} xs={12} sm={4}>
            <Paper
              sx={{
                p: 3,
                textAlign: 'center',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3
                }
              }}
            >
              {step.icon}
              <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                {step.title}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default HowItWorks;
