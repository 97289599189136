import { toast } from "react-toastify";

export async function fetchData(url, Init, successMessage) {
  const id = toast.loading("Please wait...");

  try {
    const response = await fetch(url, Init);

    if (!response.ok) {
      console.log(response);
      const errorMessage = `HTTP error: ${response.status}`;
      toast.update(id, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error(errorMessage);
      return response.json();
    }

    toast.update(id, {
      render: successMessage,
      type: "success",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    return response.json();
  } catch (error) {
    toast.update(id, {
      render: `Error: ${error.message}`,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    console.error("Error fetching data:", error);
    return
  }
}

export async function postData(url, payload) {
  const Init = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    credentials: "include",
    mode: "cors",
    cache: "default",
    body: JSON.stringify(payload),
  };

  try {
    return fetchData(url, Init, "Successfully");
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}

export async function postDataWithAuth(url, payload, token) {
  const Init = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(payload),
  };

  try {
    return fetchData(url, Init, "Successfully");
  } catch (error) {
    console.error("Error posting data with authentication:", error);
    throw error;
  }
}

export async function postBinary(url, payload) {
  const Init = {
    method: "POST",
    mode: "cors",
    credentials: "include",
    cache: "default",
    body: payload,
  };

  try {
    return fetchData(url, Init, "Successfully");
  } catch (error) {
    console.error("Error posting binary data:", error);
    throw error;
  }
}
