import { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chart from "react-apexcharts";
import { H5 } from "../components/Typography";
import { FlexBetween } from "../components/flex-box";

import { alpha } from "@mui/material/styles";

// styled component
const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.grey[600],
  "& fieldset": {
    border: "0 !important",
  },
  "& .MuiSelect-select": {
    padding: 0,
    paddingRight: "8px !important",
  },
}));

const Analytics = ({ details }) => {
  const [selectType, setSelectType] = useState(0);

  if (!details) {
    return "Loading";
  }

  // console.log(details[selectType]?.labels)

  const series = [
    {
      name: "Debit",
      data: details[selectType]?.debit,
    },
    {
      name: "Credit",
      data: details[selectType]?.credit,
    },
    {
      name: "total",
      data: details[selectType]?.data,
    },
  ];

  const useChartOptions = () => {
    const theme = useTheme();

    const categories = details[selectType]?.labels
      ? details[selectType]?.labels
      : [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

    return {
      chart: {
        background: "transparent",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      colors: [
        theme.palette.primary.main,
        alpha(theme.palette.error.main, 0.25),
        theme.palette.success.main,
      ],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
        type: "solid",
      },
      grid: {
        borderColor: theme.palette.divider,
        strokeDashArray: 2,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "8px",
        },
      },
      stroke: {
        colors: ["transparent"],
        show: true,
        width: 2,
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true,
        },
        categories,
        labels: {
          offsetY: 5,
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => (value > 0 ? `${value}` : `${value}`),
          offsetX: -10,
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
    };
  };

  const chartOptions = useChartOptions();

  return (
    <Card
      sx={{
        p: 3,
        height: "100%"
      }}
    >
      <FlexBetween>
        <H5>Analytics</H5>

        <StyledSelect
          value={selectType}
          IconComponent={() => <KeyboardArrowDown />}
          onChange={(e) => setSelectType(e.target.value)}
        >
          {details.map((value, key) => (
            <MenuItem key={key} value={key}>
              {value.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FlexBetween>

      <Chart
        type="bar"
        height={350}
        series={series}
        options={chartOptions}
        width="100%"
      />
    </Card>
  );
};
export default Analytics;
