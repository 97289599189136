import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Fade() {
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplaySpeed: 2000
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
      <div>
          <img src={"/static/img/transaction2.png"} width="100%" />
        </div>
        <div>
          <img src={"/static/img/transfer.png"} width="100%" />
        </div>
        <div>
          <img src={"/static/img/gift.png"} width="100%" />
        </div>
        <div>
          <img src={"/static/img/topup.webp"} width="100%" />
        </div>
      </Slider>
    </div>
  );
}

export default Fade;
