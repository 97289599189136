import { useCallback } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { OverviewBudget } from "../../pages-sections/overview/overview-budget";

import HorizontalScrollCardGrid from "../../components/horizontalScrollCardGrid";

import { useGet } from "../../hooks/useGet";

export const SettingsNotifications = () => {
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get_withdraw/`
  );

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="Manage the withdraw" title="WithDraw Option" />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid xs={12} sm={6} md={12}>
              <HorizontalScrollCardGrid
                data={data}
                loading={loading}
                CardItem={OverviewBudget}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {/** 
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained">Save</Button>
        </CardActions>
        */}
      </Card>
    </form>
  );
};
