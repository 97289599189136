import React from "react";
import Grid from "@mui/material/Grid";

export default function complete() {
  return (
    <Grid container spacing={0}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <img
          width={116}
          height={116}
          alt="complete"
          src="https://funtura.in/tvm/wp-content/themes/funtura/assets/images/success.svg"
        />
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        mt={5}
      >
        <h3 lineHeight={1.1} mt="1.5rem">
          Your transaction is completed!
        </h3>
      </Grid>
      <Grid m={0} item md={12}>
        <p style={{ textAlign: "center" }} color="grey.800" mt="0.3rem">
          You will be receiving confirmation email with transaction details.
        </p>
      </Grid>
    </Grid>
  );
}
