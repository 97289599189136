import React, { useState } from "react";

import { Layout } from "../layouts/dashboard/layout";
import Grid from "@mui/material/Grid";

import Gift from "../pages-sections/gift/index";

import Dialog from "../components/dialog";
import OTPForm from "../pages-sections/account/user/verify";

import { useGet } from "../hooks/useGet";
import { toast } from "react-toastify";
import { postData } from "../fetch/post";
import { useNavigate } from "react-router-dom";

export default function GiftPage() {
  const navigate = useNavigate();
  const [cardSelect, setCardSelect] = useState({
    first_name: "",
    last_name: "",
    email: "",
    quantity: 1,
    amount: 0,
  });

  const [token_reset, l, err] = useGet(
    `${process.env.REACT_APP_GIFT}/get_token/`
  );

  const [products, loading, error] = useGet(
    `${process.env.REACT_APP_GIFT}/get_products/`
  );

  const [otp, setOTP] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // for otp modal
  const [sent, setSent] = React.useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  // Function to handle sending OTP to API
  const sendOTPToAPI = async () => {
    setSent(true);
    // Send OTP to API
    console.log("Sending OTP to API:", otp);
    // You can use fetch or any other method to send OTP to the API
    const res = await postData(`${process.env.REACT_APP_USER}/verify_otp/`, {
      pin: otp,
    });
    if (res.message === "success") {
      setOpen(!open);
      setSent(false);
      handleSentData();
      return;
    } else {
      toast(res.message);
      setSent(false);
    }
    setSent(false);
  };

  const handleSentData = async () => {
    const res = await postData(`${process.env.REACT_APP_GIFT}/order/`, {
      card: cardSelect,
      pin: otp,
    });
    if (res.message === "success") {
      toast("Gift Card sent to your Email");
      navigate("/");
      return;
    }
    toast(res.message);
    return;
  };

  if (loading) {
    return (
      <Layout>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <h4>Loading...</h4>
        </Grid>
      </Layout>
    );
  }
  const message = "Verify with OTP";

  const handleSubmit = () => {
    // console.log("amount account", cardSelect?.account?.amount);
    //  console.log("amount", cardSelect.amount);
    //  console.log('fees', parseFloat(cardSelect.fees));
    //  console.log("fees rate", parseFloat(cardSelect.fees * cardSelect?.rate));
    //  console.log(
    //    parseFloat(cardSelect?.amount * cardSelect?.rate) +
    //      parseFloat(cardSelect.fees * cardSelect?.rate)
    //  );
    if (
      cardSelect.email &&
      cardSelect.amount > 0 &&
      cardSelect.id &&
      cardSelect.account
    ) {
      if (cardSelect?.account.currency?.code === "HTG") {
        if (
          parseFloat(cardSelect?.amount * cardSelect?.rate) +
            parseFloat(cardSelect.fees * cardSelect?.rate) >
          parseFloat(cardSelect?.account.amount)
        ) {
          toast("Insufficient Funds");
          return;
        }
        handleClose();
        return;
      }
      if (cardSelect?.account.currency?.code === "USD") {
        if (
          parseFloat(cardSelect?.amount) >
          parseFloat(cardSelect?.account.amount)
        ) {
          toast("Insufficient Funds");
          return;
        }
        handleClose();
        return;
      }
    }

    toast("email and amount required");
    toast("select Speedy account required");
  };

  return (
    <Layout>
      {products ? (
        <>
          <Dialog
            Content={
              <OTPForm
                open={sent}
                sendOTPToAPI={sendOTPToAPI}
                setOTPPass={setOTP}
              />
            }
            open={open}
            handleClose={handleClose}
            message={message}
          />
          <Gift
            handleSubmit={handleSubmit}
            data={products}
            selectCard={cardSelect}
            setSelectCard={setCardSelect}
          />
        </>
      ) : null}
    </Layout>
  );
}
