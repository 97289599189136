// src/components/HeroSection.js
import React from "react";
import { Box, Typography, Button, Grid, Link } from "@mui/material";

function HeroSection() {
  return (
    <Grid container spacing={0} mt={10}>
      <Grid item md={6}>
        <Box sx={{ pt: 8, pb: 6, textAlign: "start" }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Transférez des fonds rapidement et facilement
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            Depuis votre wallet Speedy vers n'importe quelle banque en Haïti
          </Typography>
          <Button
            LinkComponent={Link}
            href={"/login"}
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            size="large"
          >
            Commencer maintenant
          </Button>
        </Box>
      </Grid>
      <Grid item md={6}>
        <img
          src="/static/img/online-banking-person.png"
          width="100%"
          alt="wallet to bank"
        />
      </Grid>
    </Grid>
  );
}

export default HeroSection;
