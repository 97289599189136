// src/components/VideoTutorial.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function VideoTutorial() {
  return (
    <Box sx={{ pt: 8, pb: 6, textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom>
      Regardez le tutoriel ci-dessous
      </Typography>
      <Box sx={{ mt: 4 }}>
        <iframe
          width="80%"
          height="415"
          src="https://www.youtube.com/embed/example_video_id" // Remplacez "example_video_id" par l'ID réel de votre vidéo
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
    </Box>
  );
}

export default VideoTutorial;