import React, { useState } from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

import Dialog from "../../components/dialog";
import OTPForm from "../account/user/verify";
import { currency } from "../../utils/lib";

export default function Verify({
  account,
  amount,
  handleBack,
  handleSentData,
  methodPayment,
}) {

  const fees = parseFloat(amount * 0.02)

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        sx={{ padding: "20px", backgroundColor: "#f4f4f4" }}
      >
        <Typography
          align="center"
          color="text.secondary"
          sx={{
            lineHeight: "32px",
          }}
          variant="p"
        >
          Please verify your transaction before confirming it
        </Typography>
        <Grid
          container
          mt={5}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Payment Method
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {methodPayment}
            </Typography>
          </Grid>
        </Grid>

        {/* Account Number */}
        <Grid
          container
          mt={0}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Account
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {account.number} - {account.amount} {account.currency?.code}
            </Typography>
          </Grid>
        </Grid>

        {/* Amount */}
        <Grid
          container
          mt={0}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Amount
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {currency(amount, 2, account.currency?.code)}
            </Typography>
          </Grid>
        </Grid>

        {/* Fee */}
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Fees
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {currency(fees, 2, account.currency?.code)}
            </Typography>
          </Grid>
        </Grid>

        {/* Total */}
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" color="primary">
              Total Amount
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" color="primary">
              {currency(
                parseFloat(fees) + parseFloat(amount),
                2,
                account.currency?.code
              )}
            </Typography>
          </Grid>
        </Grid>

        {/* Verify Button */}
        <Grid item mt={5} md={12} xs={12}>
          <Button
            onClick={handleSentData}
            sx={{ float: "right", marginLeft: "16px" }}
            variant="outlined"
          >
            Pay
          </Button>
          <Button
            onClick={() => handleBack()}
            sx={{ float: "right" }}
            variant="outlined"
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
