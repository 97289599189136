import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = `${process.env.REACT_APP_USER}`;

export const login = createAsyncThunk(
  "user/login",

  async (_, thunkApi) => {
    const { username, password } = _;

    if (
      (username === null || password === null) &&
      (username === "" || password === "")
    ) {
      // state.message.push("invalid username or password !");
      alert("username or password missing!!!");
      return;
    }
    const payload = {
      username,
      password,
    };
    const Init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "default",
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${url}/login/`, Init);
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    const data = await response.json();
    /* eslint-disable consistent-return */
    return data;
  }
);

export const loginGoogle = createAsyncThunk(
  "user/loginGoogle",
  async (_, thunkApi) => {
    const { token } = _;
    if (token === null && token === "") {
      alert("token required");
      return;
    }

    const payload = {
      token,
    };

    const Init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "default",
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${url}/googletokensignin/`, Init);
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    const data = await response.json();
    /* eslint-disable consistent-return */
    return data;
  }
);

export const checkLogin = createAsyncThunk(
  "user/CheckLogin",

  async (_, thunkApi) => {
    const Init = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "default",
    };

    const response = await fetch(`${url}/status/`, Init);
    if (!response.ok) {
      alert("Verify your network connection or you're logout now !!!");
      // window.location.reload('/')
      // throw new Error(`HTTP error: ${response.status}`);
    }
    const data = await response.json();
    /* eslint-disable consistent-return */
    return data;
  }
);

export const userVerify = createAsyncThunk(
  "user/verify",

  async (_, thunkApi) => {
    const { otp } = _;
    if (!otp) {
      alert("Otp required !");
      return;
    }
    const Init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "default",
      body: JSON.stringify({ pin: otp }),
    };

    const response = await fetch(
      `${process.env.REACT_APP_USER}/verify_account/`,
      Init
    );
    if (!response.ok) {
      alert("Verify your network connection !!!");
      throw new Error(`HTTP error: ${response.status}`);
    }
    const data = await response.json();
    /* eslint-disable consistent-return */
    return data;
  }
);

export const register = createAsyncThunk(
  "user/register",

  async (_, thunkApi) => {
    const { email, password, re_password, first_name, last_name } = _;

    if (
      password !== re_password ||
      re_password === null ||
      password === "" ||
      password === null ||
      email === "" ||
      email === null ||
      first_name === "" ||
      first_name === null ||
      last_name === "" ||
      last_name === null
    ) {
      return;
    }

    const payload = {
      email,
      password,
      re_password: re_password,
      first_name: first_name,
      last_name: last_name,
    };

    const Init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "default",
      body: JSON.stringify(payload),
    };

    return fetch(`${url}/register/`, Init)
      .then((res) => {
        if (res.status === 201) {
          return res.json();
        }
        return false;
      })
      .then((data) => {
        if (!data || data.error) {
          return false;
        }
        return data;
      })
      .catch((err) => {
        state.error = err;
      });
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    user: {},
    loading: false,
    message: "username or password wrong !!",
    open: false,
    color: "error",
    error: true,
    sold: {
      amount: 0.0,
    },
    visibility: false,
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
      // don't do this cause the token store in cookies http read only
      // sessionStorage.setItem("zbook_user", JSON.stringify(action.payload));
      // login user in the web site
      state.isLoggedIn = true;
      state.error = false;
      state.color = "success";
      state.message = " successfully login";
      window.sessionStorage.setItem("user", JSON.stringify(action.payload.user));
      return;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      if (action.payload === false) {
        state.message = "Email already use by someone else";
        return;
      }
      state.user = action.payload.user;
      // don't do this cause the token store in cookies http read only
      // sessionStorage.setItem("zbook_user", JSON.stringify(action.payload));
      // login user in the web site
      // save user in session
      // sessionStorage.setItem("zbook_user", JSON.stringify(data));
      // login user in the web site
      state.color = "success";
      state.message = "successfully logged in";
      state.user.isLoggedIn = true;
      window.sessionStorage.setItem("user", JSON.stringify(action.payload.user));
      // window.location.reload();
      return;
    });

    builder.addCase(checkLogin.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.isLoggedIn = action.payload.status;
        state.user = action.payload.user;
        state.loading = false;
        window.sessionStorage.setItem("user", JSON.stringify(action.payload.user));
        return;
      }
      state.isLoggedIn = false;
      state.loading = false;
      window.sessionStorage.clear();
      return;

      // don't do this cause the token store in cookies http read only
      // sessionStorage.setItem("zbook_user", JSON.stringify(action.payload));
      // login user in the web site
    });
    builder.addCase(userVerify.fulfilled, (state, action) => {
      if (action.payload.message === "success") {
        window.sessionStorage.setItem("user", JSON.stringify(action.payload.data));
        state.user = action.payload.data;
        return;
      }
      toast(" pin expire or not match ");
      return;
      // don't do this cause the token store in cookies http read only
      // sessionStorage.setItem("zbook_user", JSON.stringify(action.payload));
      // login user in the web site
    });
    builder.addCase(loginGoogle.fulfilled, (state, action) => {
      state.user = action.payload.user;
      // don't do this cause the token store in cookies http read only
      // sessionStorage.setItem("zbook_user", JSON.stringify(action.payload));
      // login user in the web site
      state.isLoggedIn = true;
      state.error = false;
      state.color = "success";
      state.message = " successfully login";
      window.sessionStorage.setItem("user", JSON.stringify(action.payload.user));
      return;
    });
  },

  reducers: {
    handleVisibility: (state) => {
      state.visibility = !state.visibility;
    },

    logout: (state) => {
      const Init = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        credentials: "include",
        mode: "cors",
        cache: "default",
      };

      fetch(process.env.REACT_APP_USER + "/logout/", Init)
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then((data) => {
          if (!data || data.Error) {
            return false;
          }

          window.sessionStorage.clear();
          window.location.reload();
          return;
        })
        .catch((err) => {
          alert(err);
        });

      // window.location.reload();
    },
  },
});

export const { logout, handleVisibility } = userSlice.actions;
