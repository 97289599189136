import React, { useState, useEffect } from "react";
import CurrencyExchangeOutlined from "@mui/icons-material/CurrencyExchangeOutlined";
import AutoCompletion from "../../../components/autoComplete";
import {
  Grid,
  Card,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";

export default function Convert({
  data,
  listAccount,
  amount,
  accountReceive,
  setAccountReceive,
  setAmount,
  setAccount,
  account,
  handleComplete,
}) {
  const [dataFilter, setDataFilter] = useState(listAccount);

  const handleChangeAccount = (value) => {
    setDataFilter([]);
    setAccountReceive(null);
    setAccount(value);

    const temp = listAccount.filter((item) => {
      return item.number !== value.number;
    });

    setDataFilter((prev) => [...prev, ...temp]);
  };

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        sx={{ padding: "20%, 10%" }}
      >
        <Typography
          align="center"
          color="text.secondary"
          sx={{
            lineHeight: "32px",
          }}
          variant="p"
        >
          Sent devis
        </Typography>
        <Grid container mt={3} spacing={{ md: 3, xs: 1 }}>
          {/** sender account */}
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <AutoCompletion
              placeholder="Select Account"
              value={account}
              handleChange={(value) => handleChangeAccount(value)}
              data={data}
              show
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>

          {/** recieve account */}
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <AutoCompletion
              placeholder="Select Account Recieve"
              value={accountReceive}
              handleChange={(value) => setAccountReceive(value)}
              data={dataFilter}
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {account ? account.currency?.code : "$"}
                  </InputAdornment>
                ), min:1
              }}
              fullWidth
              name="amount"
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value.replace(/-/g, '')))}
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <Button
              onClick={handleComplete}
              startIcon={<CurrencyExchangeOutlined />}
              fullWidth
              variant="outlined"
            >
              Convert Devis
            </Button>
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
