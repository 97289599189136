import React from "react";
import { Layout } from "../../layouts/dashboard/layout";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography'

export default function CardPage() {
  return (
    <Layout>
      <Grid mt={5} container spacing={0}>
      <Grid item md={6}>
        <Typography textAlign="start" variant="h5" color="initial">
          Coming soon ...
        </Typography>
      </Grid>
        <Grid
          mt={3}
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
        
          <img width="80%" height="650px" src="/static/img/credit.png" alt="img credit" />
        </Grid>
      </Grid>
    </Layout>
  );
}
