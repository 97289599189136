// src/components/FAQ.js
import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'Comment créer un compte ?',
    answer: 'Inscrivez-vous facilement avec votre adresse email.'
  },
  {
    question: 'Comment ajouter des fonds ?',
    answer: 'Ajoutez des fonds à votre wallet Speedy de manière sécurisée et rapide.'
  },
  {
    question: 'Quels sont les frais de transfert ?',
    answer: 'Les frais varient en fonction du montant et de la destination. Consultez notre section Tarifs pour plus de détails.'
  }
];

function FAQ() {
  return (
    <Box sx={{ pt: 8, pb: 6 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        FAQ
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default FAQ;
