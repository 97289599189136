import React, { useState } from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

import Dialog from "../../components/dialog";
import FormGiftCard from "../../components/FormGiftCard";

export default function Index({
  data,
  setSelectCard,
  selectCard,
  handleSubmit,
}) {
  function filterProductsByName(names) {
    return data.filter((product) =>
      names.some((name) => product.productName.includes(name))
    );
  }

  const filteredProducts = filterProductsByName([
    "Xbox Live",
    "Xbox Game Pass Ultimate",
    "Amazon",
    "Walmart",
    "MasterCard",
    "Best",
    "Netflix",
    "Uber",
    "ebay",
    "Google",
    "App",
    "Uber",
    "Visa",
    "Mastercard",
    "Spotify",
    "PlayStation",
    "Apple",
    "Apple Store",
    "Bitcoin",
    "Binance",
    "Itunes",
  ]);

  console.log(filteredProducts);
  return (
    <Card
      sx={{
        padding: { xs: "2%", md: "2%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Typography variant="h5" color="initial">
        Create Gift Card
      </Typography>
      <Grid container mt={3} spacing={3} sx={{ padding: "20%, 10%" }}>
        {filteredProducts.map((value, key) => (
          <Grid key={key} item md={3} xs={6}>
            <Products
              item={value}
              setSelectCard={setSelectCard}
              selectCard={selectCard}
              handleSubmit={handleSubmit}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

const Products = ({ item, setSelectCard, selectCard, handleSubmit }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setSelectCard({
      first_name: "",
      last_name: "",
      email: "",
      quantity: 1,
      amount: 0,
    });
    setOpen(!open);
  };
  return (
    <>
      <Dialog
        open={open}
        handleClose={handleClose}
        message={"Gift Card Purchase"}
        Content={
          <FormGiftCard
            selectCard={selectCard}
            card={item}
            setSelectCard={setSelectCard}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        }
      />
      <Card
        onClick={handleClose}
        sx={{
          cursor: "pointer",
          m: 0,
          width: "100%",
          height: { md: "78%", xs: "78%" },
          backgroundColor: "#fff",
        }}
      >
        <img
          src={item ? item.logoUrls[0] : ""}
          alt="card image"
          style={{ width: "100%" }}
        />
      </Card>
      <Typography variant="body1" color="initial">
        {item.productName}
      </Typography>
    </>
  );
};
