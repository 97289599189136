import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import { Layout } from "../layouts/auth/layout";
import { postData } from "../fetch/post";

export default function forgetPasswordPage() {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      const data = await postData(
        `${process.env.REACT_APP_USER}/email_for_change_password/`,
        {
          email,
        }
      );
      if (data.message === "success") {
        toast("email sent successfully !");
        return;
      }
      toast(`${data.message}`);
      return;
    } else {
      toast("email required");
      return;
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader subheader="Your email" title="Email" />
              <Divider />
              <CardContent>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    type="email"
                    value={email}
                  />
                </Stack>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button fullWidth type="submit" variant="contained">
                  Send Link Reset Password
                </Button>
              </CardActions>
            </Card>
          </form>
        </Box>
      </Box>
    </Layout>
  );
}
