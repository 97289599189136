import React from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from "@mui/material";
import Header from "../pages-sections/home/Header";
import Footer from "../pages-sections/home/Footer";

const transactionFees = [
  {
    type: "Dépôt",
    fees: [
      { description: "Dépôt via carte de crédit", amount: "5%" },
      { description: "Dépôt via virement bancaire", amount: "Gratuit" },
      { description: "Dépôt via Moncash Auto", amount: "2%" },
      { description: "Dépôt via Moncash manuel", amount: "Gratuit" },
      { description: "Dépôt via Natcash manuel", amount: "Gratuit" },
    ],
  },
  {
    type: "Transfert",
    fees: [
      {
        description: "Transfert vers Speedy wallet à Speedy wallet HTG",
        amount: "50 HTG",
      },
      {
        description: "Transfert vers Speedy wallet à Speedy wallet USD",
        amount: "0.5 USD",
      },
      {
        description: "Transfert Speedy wallet USD vers Speedy wallet HTG",
        amount: "Taux du jour",
      },
      {
        description: "Transfert Speedy wallet HTG vers Speedy wallet USD",
        amount: "Taux du jour",
      },
    ],
  },
  {
    type: "Retrait",
    fees: [
      { description: "Retrait via WISE", amount: "9%" },
      { description: "Retrait via virement bancaire SOGEBANK", amount: "7%" },
      { description: "Retrait via virement bancaire BUH", amount: "6%" },
      { description: "Retrait via virement bancaire UNIBANK", amount: "7%" },
    ],
  },
];

function Pricing() {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Container>
        <Box sx={{ mt: 10, mb: 4}}>
          <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: theme.palette.primary.main }}>
            Frais de Transaction
          </Typography>
          <Typography variant="subtitle1" paragraph align="center">
            Découvrez les frais associés à nos différents services de dépôt, transfert et retrait.
          </Typography>
          <Typography variant="subtitle2" paragraph align="center" sx={{ fontStyle: 'italic' }}>
            Note : Toutes les transactions devraient être traitées entre 15 minutes et 24 heures.
          </Typography>

          <TableContainer component={Paper} sx={{ mt: 4, width: "100%", boxShadow: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="transaction fees table">
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                  <TableCell sx={{ fontWeight: 'bold', color: theme.palette.common.white }}>Type de Transaction</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: theme.palette.common.white }}>Description</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold', color: theme.palette.common.white }}>Frais</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionFees.map((transaction, index) => (
                  <React.Fragment key={index}>
                    {transaction.fees.map((fee, idx) => (
                      <TableRow key={idx} sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}>
                        {idx === 0 && (
                          <TableCell
                            rowSpan={transaction.fees.length}
                            sx={{
                              borderRight: `2px solid ${theme.palette.divider}`,
                              fontWeight: "bold",
                              verticalAlign: "top",
                            }}
                          >
                            {transaction.type}
                          </TableCell>
                        )}
                        <TableCell>{fee.description}</TableCell>
                        <TableCell align="right">{fee.amount}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default Pricing;
