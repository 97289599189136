import React from "react";
import { Container } from "@mui/material";

import Header from "./Header";
import HeroSection from "./HeroSection";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import Testimonials from "./Testimonials";
import VideoTutorial from "./VideoTuto";
import FAQ from "./Faq";
import ContactForm from "./contact";
import Footer from "./Footer";

export default function index() {
  return (
    <Container maxWidth="lg">
      <Header />
      <HeroSection />
      <Features />
      <HowItWorks />
      <Testimonials />
      <VideoTutorial />
      <FAQ />
      <ContactForm />
      <Footer />
    </Container>
  );
}
