import React from "react";
import {
  Container,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import SecurityIcon from "@mui/icons-material/Security";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../pages-sections/home/Header";
import Footer from "../pages-sections/home/Footer";

function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Box sx={{ mt: 10 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Politique de Confidentialité
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Typography variant="body1" paragraph>
            Chez SpeedyTransfer, nous nous engageons à protéger et à respecter
            votre vie privée. Cette Politique de Confidentialité explique
            comment nous collectons, utilisons, stockons et protégeons vos
            informations personnelles.
          </Typography>

          <List>
            <ListItem>
              <ListItemIcon>
                <PrivacyTipIcon />
              </ListItemIcon>
              <ListItemText
                primary="1. Collecte des Informations"
                secondary="Nous collectons des informations personnelles lorsque vous utilisez nos services, telles que vos coordonnées, informations de paiement, et données de transaction."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText
                primary="2. Protection des Données"
                secondary="Nous utilisons des mesures de sécurité techniques et organisationnelles pour protéger vos informations personnelles contre l'accès non autorisé et les fuites de données."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary="3. Utilisation des Données"
                secondary="Nous utilisons vos informations personnelles pour vous fournir nos services, gérer vos transactions, et vous contacter pour des raisons de service ou de support."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText
                primary="4. Partage des Informations"
                secondary="Nous ne partageons vos informations personnelles avec des tiers que si cela est nécessaire pour fournir nos services ou si cela est requis par la loi."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="5. Vos Droits"
                secondary="Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles. Vous pouvez également vous opposer à leur traitement dans certaines circonstances."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <DataObjectIcon />
              </ListItemIcon>
              <ListItemText
                primary="6. Conservation des Données"
                secondary="Nous conservons vos données personnelles uniquement pendant la durée nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText
                primary="7. Modifications de la Politique"
                secondary="Nous pouvons modifier cette politique de confidentialité de temps en temps. Les changements seront publiés sur notre site Web et seront effectifs dès leur publication."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary="8. Contact"
                secondary={
                  <>
                    Si vous avez des questions concernant notre Politique de
                    Confidentialité, veuillez nous contacter à l'adresse
                    suivante :
                    <br />
                    - Email : support@speedytransfer.io
                    <br />- Adresse : 123 Rue de la Paix, Port-au-Prince, Haïti
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
