
import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";

import ContactFormSchema from "../yup/contactFormSchema";

import { postData } from "../fetch/post";

export default function ContactForm() {

    const onSubmit = (values, actions) => {
        const data = postData(process.env.NEXT_PUBLIC_CONTACT_US, values);
        if (data.error) setErr(data);
        if (!data) setLoad(true);
        actions.resetForm();
      };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
          name: "",
          email: "",
          number: "",
          message: "",
        },
        validationSchema: ContactFormSchema,
        onSubmit,
    });

    
  return (
    <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
                <TextField
                    error={errors.name && touched.name}
                    placeholder='Nom'
                    fullWidth
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item md={6} xs={6}>
                <TextField
                    error={errors.email && touched.email}
                    helperText={errors.email}
                    placeholder='email'
                    fullWidth
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>

            <Grid item md={12} xs={12} >
                <TextField
                    error={errors.number && touched.number}
                    helperText={errors.number}
                    placeholder='Votre numero de telephone'
                    fullWidth
                    value={values.number}
                    name="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>

            <Grid item md={12} xs={12}>
            <TextField
                error={errors.message && touched.message}
                helperText={errors.message}
                placeholder='Votre numero de telephone'
                fullWidth
                multiline
                rows={12}
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                />
            </Grid>

            <Grid item md={12} xs={12}>
                <Button
                type="submit"
                variant="outlined"
                fullWidth
                style={{padding: "30px",}}
                >
                    Envoyer
                </Button>
            </Grid>
            
          
        </Grid>
    </form>
  );
}
