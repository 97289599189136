// src/components/Features.js
import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import Slide from "../../components/slider";

function Features() {
  return (
    <Box id="features" sx={{ pt: 8, pb: 6 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Profitez des avantages de Speedytransfer
      </Typography>

      <Typography align="center">
        Speedy Transfer est un système de transfert de fonds qui vous permet
        d'envoyer des fonds entre votre portefeuille électronique et vos comptes
        bancaires
      </Typography>
      <Grid container spacing={5}>
        <Grid item md={6}>
          <Slide />
        </Grid>
        <Grid item md={6}>
          <Box sx={{  pt: 8, pb: 0, mt: 6, textAlign: "start" }}>
            <Typography variant="h6" component="h1" gutterBottom>
              <CheckIcon color="primary" /> Transfert rapide de fonds
            </Typography>
            <Typography variant="p" component="p" gutterBottom>
              Évitez les longues files d'attente et déposez des fonds
              directement sur votre compte.
            </Typography>
          </Box>
          <Box sx={{ pt: 4, pb: 0, textAlign: "start" }}>
            <Typography variant="h6" component="h1" gutterBottom>
              <CheckIcon color="primary" /> Achat de cartes cadeaux
            </Typography>
            <Typography variant="p" component="p" gutterBottom>
              Achetez des cartes cadeaux pour vos proches en quelques clics.
            </Typography>
          </Box>
          <Box sx={{ pt: 4, pb: 0, textAlign: "start" }}>
            <Typography variant="h6" component="h1" gutterBottom>
              <CheckIcon color="primary" /> Envoi de top-up
            </Typography>
            <Typography variant="p" component="p" gutterBottom>
              Rechargez facilement le téléphone de vos proches.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Features;
