import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";

import { Layout } from "../layouts/dashboard/layout";
import Stepper from "../components/stepper";

import SelectOption from "../pages-sections/topup/SelectOption";
import VerifyTopup from "../pages-sections/topup/verify";

import { useGet } from "../hooks/useGet";
import { postData } from "../fetch/post";

const steps = ["Number", "Amount or bubble", "review", "pay"];

export default function TopupPage() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [phone, setPhone] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("");
  const [operatorId, setOperatorId] = useState("");

  const [otp, setOTP] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // for otp modal
  const [sent, setSent] = React.useState(false);

  const [token_reset, l, err] = useGet(
    `${process.env.REACT_APP_TOPUP}/get_token/`
  );

  // Function to handle sending OTP to API
  const sendOTPToAPI = async () => {
    setSent(true);
    // Send OTP to API
    console.log("Sending OTP to API:", otp);
    // You can use fetch or any other method to send OTP to the API
    const res = await postData(`${process.env.REACT_APP_USER}/verify_otp/`, {
      pin: otp,
    });
    if (res.message === "success") {
      setOpen(!open);
      setSent(false);
      handleSentData();
      return;
    } else {
      toast(res.message);
      setSent(false);
    }
    setSent(false);
  };

  const [data, loading2, error2] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get/`
  );

  const [operators, loading, error] = useGet(
    `${process.env.REACT_APP_TOPUP}/get_operators/`
  );

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const allStepsCompleted = () => {
    return Object.keys(completed).length === steps.length;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  useEffect(() => {
    if (phone.length > 3) {
      console.log(phone.length);
      if (phone.length >= 11) {
        // for sync account
        setAccount(data[0]);
        handleComplete();
      } else {
        setActiveStep(0);
        setCompleted({});
      }
    }
  }, [phone]);

  const [account, setAccount] = useState();

  if (loading) {
    return (
      <Layout>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <h4>Loading...</h4>
        </Grid>
      </Layout>
    );
  }

  const handleReview = () => {
    if (amount && operatorId && phone) {
      handleComplete();
    } else {
      toast("amount && operatorId && phone required");
      return;
    }
  };

  const handleSentData = async () => {
    const res = await postData(`${process.env.REACT_APP_TOPUP}/topup/`, {
      amount,
      operator_id: operatorId,
      number: phone,
      pin: otp,
      rate,
      account_id: account.id,
    });
    if (res.message === "success") {
      toast("Top-up sent successfully");
      handleComplete();
      return;
    } else {
      toast(res.message);
      return;
    }
  };

  return (
    <Layout>
      <Box>
        <Grid
          container
          mt={5}
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Stepper
            steps={steps}
            activeStep={activeStep}
            completed={completed}
          />
        </Grid>
        {activeStep === 2 ? (
          <VerifyTopup
            accounts={data}
            account={account}
            setAccount={setAccount}
            accountRecieve={phone}
            amount={amount}
            handleBack={handleBack}
            setOTP={setOTP}
            sent={sent}
            sendOTPToAPI={sendOTPToAPI}
            open={open}
            setOpen={setOpen}
            rate={rate}
          />
        ) : (
          <SelectOption
            operators={operators}
            handleComplete={handleComplete}
            phone={phone}
            setPhone={setPhone}
            setOperatorId={setOperatorId}
            setAmount={setAmount}
            handleReview={handleReview}
            setRate={setRate}
          />
        )}
      </Box>
    </Layout>
  );
}
