import { useState, useEffect } from 'react';

const useFetch = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();

        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return [data, loading, error];
};

const useGet = (url) => {
  return useFetch(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    credentials: 'include',
    mode: 'cors',
    cache: 'default',
  });
};

const useGetWithAuth = (url, token) => {
  return useFetch(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
    mode: 'cors',
    cache: 'default',
  });
};

export { useGet, useGetWithAuth };
