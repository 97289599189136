import React from "react";
import {
  Container,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SearchIcon from "@mui/icons-material/Search";
import SchoolIcon from "@mui/icons-material/School";
import FolderIcon from "@mui/icons-material/Folder";
import ReportIcon from "@mui/icons-material/Report";
import PeopleIcon from "@mui/icons-material/People";
import UpdateIcon from "@mui/icons-material/Update";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../pages-sections/home/Header";
import Footer from "../pages-sections/home/Footer";

function AMLPolicies() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Container sx={{ mt: 8 }}>
        <Box sx={{ mt: 10 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Politiques de Lutte contre le Blanchiment d'Argent (AML)
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Typography variant="body1" paragraph>
            Chez SpeedyTransfer, nous nous engageons à maintenir les normes les
            plus élevées de conformité en matière de lutte contre le blanchiment
            d'argent (AML). Ces politiques ont pour but de prévenir et de
            détecter toute activité illégale, y compris le blanchiment d'argent
            et le financement du terrorisme.
          </Typography>

          <List>
            <ListItem>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText
                primary="Conformité Réglementaire"
                secondary="Nous nous conformons à toutes les lois et réglementations applicables en matière de lutte contre le blanchiment d'argent."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText
                primary="Identification et Vérification des Clients (KYC)"
                secondary="Nous appliquons des procédures strictes de connaissance du client (KYC) pour vérifier l'identité de nos clients."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText
                primary="Surveillance et Détection des Transactions"
                secondary="Nous surveillons les transactions de nos clients pour détecter toute activité suspecte ou inhabituelle."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText
                primary="Formation et Sensibilisation"
                secondary="Nous fournissons une formation continue à nos employés sur les politiques et procédures AML."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText
                primary="Conservation des Documents"
                secondary="Nous conservons tous les documents relatifs à l'identification des clients et aux transactions pendant une période minimale."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Signalement des Activités Suspectes"
                secondary="Nous signalons toutes les activités suspectes aux autorités compétentes."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Collaboration avec les Autorités"
                secondary="Nous collaborons étroitement avec les autorités de régulation et les forces de l'ordre."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText
                primary="Mise à Jour des Politiques"
                secondary="Nous révisons régulièrement nos politiques AML pour nous assurer qu'elles sont à jour."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary="Contact"
                secondary={
                  <>
                    Si vous avez des questions concernant nos politiques AML,
                    veuillez nous contacter à l'adresse suivante :
                    <br />
                    - Email : support@speedytransfer.io
                    <br />- Adresse : 123 Rue de la Paix, Port-au-Prince, Haïti
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default AMLPolicies;
