import { formatDistanceToNow } from "date-fns";
import PropTypes from "prop-types";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import EllipsisVerticalIcon from "@heroicons/react/24/solid/EllipsisVerticalIcon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import { stringAvatar } from "../companies/company-card";

export const OverviewLatestProducts = (props) => {
  const { products = [], sx } = props;

  return (
    <Card sx={sx}>
      <CardHeader title="Friend and Family" />
      <List>
        {products.map((product, index) => {
          const hasDivider = index < products.length - 1;
          //const ago = product?.currencys?.update_at // formatDistanceToNow(product?.currencys?.update_at);

          return (
            <ListItem divider={hasDivider} key={product.id}>
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(
                    `${product.bank_name} ${product.user.username}`
                  )}
                />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <Grid container spacing={0}>
                    <Grid item md={12}>
                      <Typography
                        color="text.secondary"
                        display="inline"
                        variant="h6"
                        sx={{ float: "right" }}
                      >
                        {product.number}
                      </Typography>
                      <br />
                      <Typography
                        color="text.secondary"
                        display="inline"
                        variant="h6"
                        sx={{ float: "right" }}
                      >
                        {product.bank_name}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondaryTypographyProps={{ variant: "body2" }}
              />
              <IconButton edge="end">
                <SvgIcon>
                  <EllipsisVerticalIcon />
                </SvgIcon>
              </IconButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      {/**
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
       */}
    </Card>
  );
};

OverviewLatestProducts.propTypes = {
  products: PropTypes.array,
  sx: PropTypes.object,
};
