import {
  Box,
  Container,
  Pagination,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { CompanyCard } from "./company-card";
import { CompaniesSearch } from "./companies-search";
import { useGet } from "../../hooks/useGet";

import MapSection from "./mapSection";

const Page = () => {
  const [data, loading, error] = useGet(`${process.env.REACT_APP_USER}/`);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Currency Traders</Typography>
              </Stack>
            </Stack>
            <CompaniesSearch />
            <Grid container spacing={3}>
              {data?.map((company) => (
                <Grid xs={12} md={6} lg={4} key={company.id}>
                  <CompanyCard company={company} />
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination count={3} size="small" />
            </Box>
          </Stack>
          <MapSection />
        </Container>
      </Box>
    </>
  );
};

export default Page;
