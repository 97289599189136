import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "../utils/PrivateRoute";

// layoutes

// pages
import Home from "../pages/App";
import AMLPolicies from "../pages/aml";
import TermsOfService from "../pages/condition";
import PrivacyPolicy from "../pages/privacy";

import Login from "../pages/login";
import Register from "../pages/register";

import Dashboard from "../pages/dashboard";
import Customer from "../pages/customer";
import Company from "../pages/companies";
import Account from "../pages/account";
import WalletPage from "../pages/wallet";
import NotFoundPage from "../pages/404";
import VerifyPage from "../pages/user/verify";
import WithDrawPage from "../pages/transaction/withdraw";
import SendPage from "../pages/transaction/send";
import SendBYUSERPage from "../pages/transaction/sendBYUSERPage";
import AddPage from "../pages/transaction/add";

import ForgetPasswordPage from "../pages/forget_password";
import ResetPasswordPage from "../pages/reset_password";

import SettingsPage from "../pages/settings";
import ConvertPage from "../pages/transaction/convert";
import DetailsPage from "../pages/transaction/details";
import Progress from "../utils/progress";
import TopupPage from "../pages/topup";
import GiftPage from "../pages/gift";
import CardPage from "../pages/card";
import Complete from "../pages/Complete";
import Pricing from "../pages/pricing";

function RoutesPages() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  console.log("user is :" + isLoggedIn);
  return (
    <Router>
      {/**
       * protected route
       * */}
      <Routes>
        {/** Auth  */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/aml-policies" element={<AMLPolicies />} />
        <Route exact path="/condition" element={<TermsOfService />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/pricing" element={<Pricing />} />

        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forget_password" element={<ForgetPasswordPage />} />
        <Route exact path="/reset_password" element={<ResetPasswordPage />} />

        {/** Transaction  */}
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/transaction/withdraw" element={<WithDrawPage />} />
        <Route exact path="/transaction/send" element={<SendPage />} />
        <Route exact path="/transaction/add" element={<AddPage />} />
        <Route
          exact
          path="/transaction/moncash/complete"
          element={<Complete />}
        />
        <Route exact path="/transaction/convert" element={<ConvertPage />} />
        <Route path="/transaction/details/:id" element={<DetailsPage />} />
        <Route path="/transaction/user/:id" element={<SendBYUSERPage />} />

        <Route exact path="/settings" element={<SettingsPage />} />

        <Route exact path="/topup" element={<TopupPage />} />
        <Route exact path="/gift_card" element={<GiftPage />} />
        <Route exact path="/card" element={<CardPage />} />

        {/** protect route  */}
        <Route exact path="/user/verify" element={<VerifyPage />} />
        <Route exact path="/customers" element={<Customer />} />
        <Route exact path="/traders" element={<Company />} />
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/wallets" element={<WalletPage />} />
        <Route exact path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default RoutesPages;
