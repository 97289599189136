import React, { useState, useEffect } from "react";
import { useMap } from "react-leaflet";

import Grid from "@mui/material/Grid";
import { useGet } from "../../hooks/useGet";

import Map from "../../components/map";

export default function Lookup({lat, lng, setLat, setLng}) {
  const [data, loading, error] = useGet(`${process.env.REACT_APP_USER}/`);

  function MyComponent() {
    const map = useMap();

    map.addEventListener("click", (e) => {
      setLat(e.latlng.lat);
      setLng(e.latlng.lng);
    });

    return null;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    <h4>Loading...</h4>;
  }

  return (
    <Grid container spacing={0}>
      <Grid item md={12}>
        <h5>Maps Traders</h5>
        <hr />
        {data ? (
          <Map community={data} MyComponent={MyComponent} />
        ) : (
          "Loading ..."
        )}
      </Grid>
    </Grid>
  );
}
