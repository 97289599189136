// src/components/Header.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Container,
  Link as Lien
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Header(props) {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { window } = props;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Accueil" />
        </ListItem>
        <ListItem button component={Link} to="/features">
          <ListItemText primary="Fonctionnalités" />
        </ListItem>
        <ListItem button component={Link} to="/pricing">
          <ListItemText primary="Tarifs" />
        </ListItem>
        <ListItem button component={Link} to="/faq">
          <ListItemText primary="FAQ" />
        </ListItem>
        <ListItem button component={Link} to="/contact">
          <ListItemText primary="Contact" />
        </ListItem>
        {user.isLoggedIn ? (
          <ListItem button component={Link} to="/dashboard">
            <ListItemText primary="Dashboard" />
          </ListItem>
        ) : (
          <ListItem button component={Lien} href="/register">
            <ListItemText primary="S'inscrire" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#fff" }}>
      <Container>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Link to={"/"}>
              <img
                src="/static/img/logo.png"
                width={64}
                height={64}
                alt="logo"
              />
            </Link>
          </Box>
          {isMobile ? (
            <>
              <IconButton
                color="primary"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ marginLeft: "65%", display: { sm: "none" } }}
              >
                <MenuIcon style={{ fontSize: "3.5rem", float: "right" }} />
              </IconButton>
              <Drawer
                container={container}
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <>
              <Button color="primary" component={Link} to="/">
                Accueil
              </Button>
              <Button color="primary" component={Link} to="#features">
                Fonctionnalités
              </Button>
              <Button color="primary" component={Link} to="/pricing">
                Tarifs
              </Button>
              <Button color="primary" component={Link} to="#faq">
                FAQ
              </Button>
              <Button color="primary" component={Link} to="/contact">
                Contact
              </Button>
              {user.isLoggedIn ? (
                <Button
                  component={Link}
                  to="/dashboard"
                  color="secondary"
                  variant="contained"
                >
                  Dashboard
                </Button>
              ) : (
                <Button
                  component={Lien}
                  href="/register"
                  color="primary"
                  variant="contained"
                >
                  S'inscrire
                </Button>
              )}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
