import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Box,
} from "@mui/material";

import { Layout } from "../layouts/auth/layout";
import { postDataWithAuth } from "../fetch/post";
import { useNavigate, useParams } from "react-router-dom";

export default function resetPasswordPage() {
  const [values, setValues] = useState({
    password: "",
    re_password: "",
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const token = document.location.search.replace("?token=", " ");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (values.password && values.re_password) {
      console.log(token);
      if (values.password === values.re_password) {
        const res = await postDataWithAuth(
          `${process.env.REACT_APP_USER}/change_password/`,
          {
            password: values.password,
            re_password: values.re_password,
          },
          token
        );

        if (res.message === "success") {
          toast("Your password is change successfully");
          setValues({
            password: "",
            re_password: "",
          });
          navigate("/login");
          return;
        } else {
          toast(res.message);
          return;
        }
      } else {
        toast("password dont match");
        return;
      }
    } else {
      toast("password and re_password required");
      return;
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader subheader="Update password" title="Password" />
              <Divider />
              <CardContent>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                  />
                  <TextField
                    fullWidth
                    label="Password (re_password)"
                    name="re_password"
                    onChange={handleChange}
                    type="password"
                    value={values.re_password}
                  />
                </Stack>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button fullWidth type="submit" variant="contained">
                  Reset Password
                </Button>
              </CardActions>
            </Card>
          </form>
        </Box>
      </Box>
    </Layout>
  );
}
