// src/components/Footer.js
import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={{
        pt: 8,
        pb: 6,
        textAlign: "center",
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Link to="/privacy" color="inherit">
            Politique de confidentialité
          </Link>
        </Grid>
        <Grid item>
          <Link to="/condition" color="inherit">
            Conditions d'utilisation
          </Link>
        </Grid>
        <Grid item>
          <Link to="/aml-policies" color="inherit">
            AML Policies
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary">
        &copy; 2024 SpeedyTransfer.io. Tous droits réservés.
      </Typography>
    </Box>
  );
}

export default Footer;
