import React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { usePost } from "../hooks/usePost";

import { Layout } from "../layouts/dashboard/layout";
import Stepper from "../components/stepper";

const steps = ["Amount", "Verify", "Payment"];

export default function Complete() {
  const [activeStep, setActiveStep] = React.useState(2);
  const [completed, setCompleted] = React.useState({
    0: true,
    1: true,
    2: true,
  });

  // Accessing location object using useLocation() hook
  const location = useLocation();

  // Extracting query parameter from location.search
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get("transactionId");

  const [order, error] = usePost(
    `${process.env.REACT_APP_ACCOUNT}/payment/moncash/complete/`,
    { transactionId },
    []
  );

  console.log(order)

  if (!transactionId) {
    return (
      <Layout>
        <Box>
          <Grid container spacing={0}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              mt={5}
            >
              <h3>Error on get TransactionId</h3>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    );
  }
  return (
    <Layout>
      <Box>
        <Grid container spacing={0}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            mt={5}
          >
            <Stepper
              steps={steps}
              activeStep={activeStep}
              completed={completed}
            />
          </Grid>
          {order ? (
            <>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                mt={5}
              >
                <img
                  width={116}
                  height={116}
                  alt="complete"
                  src="https://funtura.in/tvm/wp-content/themes/funtura/assets/images/success.svg"
                />
              </Grid>

              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                mt={5}
              >
                <h3 lineHeight={1.1} mt="1.5rem">
                  Your transaction is completed!
                </h3>
              </Grid>
              <Grid m={0} item md={12}>
                <p style={{ textAlign: "center" }} color="grey.800" mt="0.3rem">
                  You will be receiving confirmation email with transaction
                  details.
                </p>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                mt={5}
              >
                <h3 lineHeight={1.1} mt="1.5rem">
                  Your transaction is not completed!
                </h3>
              </Grid>
              <Grid m={0} item md={12}>
                <p style={{ textAlign: "center" }} color="grey.800" mt="0.3rem">
                  TransactionId required or payment not successfully.
                </p>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Layout>
  );
}
