import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RemoveRedEye } from "@mui/icons-material";
import { Scrollbar } from "../../../src/components/scrollbar";
import { SeverityPill } from "../../../src/components/severity-pill";
import { currency } from "../../utils/lib";
import { toggleCharactersVisibility } from "../../utils/utils";

const statusMap = {
  pending: "warning",
  delivered: "success",
  refunded: "error",
};

export const OverviewLatestOrders = (props) => {
  const { orders = [], sx } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const visibility = useSelector((state) => state.user.visibility);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card sx={sx}>
      <CardHeader title="Latest Transactions" />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ minWidth: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Debit</TableCell>
                  <TableCell>Credit</TableCell>
                  <TableCell sortDirection="desc">Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order) => {
                    const createdAt = format(order.created_at, "dd/MM/yyyy");

                    return (
                      <TableRow hover key={order.id}>
                        <TableCell>{order.invoice}</TableCell>

                        <TableCell>{order.customer}</TableCell>
                        <TableCell>
                          {currency(
                            toggleCharactersVisibility(order.debit, visibility),
                            2,
                            order?.taux?.code
                          )}
                        </TableCell>
                        <TableCell>
                          {currency(
                            toggleCharactersVisibility(
                              order.credit,
                              visibility
                            ),
                            2,
                            order?.taux?.code
                          )}
                        </TableCell>
                        <TableCell>{createdAt}</TableCell>
                        <TableCell>
                          <SeverityPill
                            color={
                              statusMap[order.is_paid ? "delivered" : "pending"]
                            }
                          >
                            {order.is_paid ? "delivered" : "pending"}
                          </SeverityPill>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            LinkComponent={Link}
                            to={`/transaction/details/${order.id}`}
                          >
                            <RemoveRedEye />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Scrollbar>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        {/**   <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
        >
          View all
        </Button>
        */}
      </CardActions>
    </Card>
  );
};

OverviewLatestOrders.prototype = {
  orders: PropTypes.array,
  sx: PropTypes.object,
};
