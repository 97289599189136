import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, MenuItem } from "@mui/material";
import AutoCompletion from "./autoComplete";

import { currency } from "../utils/lib";
import { useGet } from "../hooks/useGet";
import { usePost } from "../hooks/usePost";

export default function FormGiftCard({
  card,
  setSelectCard,
  selectCard,
  handleSubmit,
}) {
  const [accounts, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get/`
  );

  const [rate, error2] = usePost(
    `${process.env.REACT_APP_ACCOUNT}/rate/`,
    {
      rates: "HTG",
    },
    []
  );

  // Assuming selectCard.amount and card.senderFee are defined
  let baseAmount = parseFloat(selectCard.amount);
  let fee = baseAmount * 0.06 + parseFloat(card.senderFee + 1);
  let total = baseAmount + fee;

  // To fix it to 2 decimal places and convert it back to a float
  total = parseFloat(total.toFixed(2));

  // Now total holds the value you need
  console.log(total);

  const handleChange = (e) => {
    if (!e.target.value) {
      return;
    }
    if (e.target.value > 101) {
      return;
    }
    setSelectCard((prev) => {
      if (e.target.name === "amount") {
        return {
          ...prev,
          [e.target.name]: parseFloat(String(e.target.value).replace(/-/g, "")),
          fees:
            parseFloat(String(e.target.value).replace(/-/g, "")) * 0.06 +
            parseFloat(card.senderFee + 1),
          id: card.productId,
        };
      }
      return {
        ...prev,
        [e.target.name]: e.target.value,
        fees: fee,
        id: card.productId,
      };
    });
  };

  const handleChangeAccount = (value) => {
    setSelectCard((prev) => {
      return {
        ...prev,
        account: value,
        rate: rate?.currency?.taux,
        fees: fee,
      };
    });
  };

  if (rate && accounts) {
    return (
      <Grid container spacing={3}>
        <Grid item mt={3} md={12} xs={12}>
          <img src={card.logoUrls[0]} style={{ width: "100px" }} />
        </Grid>
        <Grid item md={6} xs={6}>
          <TextField
            onChange={handleChange}
            name={"first_name"}
            fullWidth
            label="First Name"
            InputProps={{
              autoFocus: true,
            }}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TextField
            onChange={handleChange}
            name={"last_name"}
            fullWidth
            label="Last Name"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TextField
            onChange={handleChange}
            name={"email"}
            fullWidth
            id=""
            label="Receive Email"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          {card.denominationType === "RANGE" ? (
            <TextField
              type="number"
              fullWidth
              onChange={handleChange}
              name="amount"
              value={selectCard.amount}
              label="Amount"
              helperText="Range 1 to 100 USD"
              inputProps={{ min: 1, max: 100 }}
            />
          ) : (
            <TextField
              type="number"
              select
              fullWidth
              name="amount"
              value={selectCard.amount}
              onChange={handleChange}
              label="Amount"
              helperText="Select Amount"
            >
              {card.fixedRecipientDenominations.map((value, key) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        {/** 
        <Grid item md={12}>
          <TextField
            name="quantity"
            value={card.quantity}
            fullWidth
            label="Quantity"
          />
        </Grid>
        */}

        <Grid item md={12} xs={12}>
          <Grid
            container
            mt={5}
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Account Sender
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <AutoCompletion
                placeholder="Select Speedy Account"
                value={selectCard.account}
                handleChange={(value) => handleChangeAccount(value)}
                data={accounts}
                show={true}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Amount
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {selectCard?.account?.currency?.code === "HTG"
                  ? currency(baseAmount * rate.currency.taux, 2, "HTG")
                  : currency(baseAmount, 2, "USD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Quantity
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {selectCard.quantity}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Gift Card
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {selectCard?.account?.currency?.code === "HTG"
                  ? currency(
                      baseAmount * rate.currency.taux,
                      2,
                      selectCard?.account.currency?.code
                    )
                  : currency(
                      baseAmount,
                      2,
                      selectCard?.account?.currency?.code
                    )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Gift Card Fees
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {selectCard?.account?.currency?.code === "HTG"
                  ? currency(
                      fee * rate.currency.taux,
                      2,
                      selectCard?.account.currency?.code
                    )
                  : currency(fee, 2, selectCard?.account?.currency?.code)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Total Payment
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {selectCard?.account?.currency?.code === "HTG"
                  ? currency(
                      total * rate.currency.taux,
                      2,
                      selectCard?.account.currency?.code
                    )
                  : currency(total, 2, selectCard?.account?.currency?.code)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              backgroundColor: "#eee",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Expected Time
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                1 business day
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Typography variant="body1" color="initial">
            {card.redeemInstruction.concise}
          </Typography>
          <Typography variant="body1" color="initial">
            {String(card.redeemInstruction.verbose).replace(".", "\n")}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Button
            onClick={handleSubmit}
            sx={{ float: "right" }}
            variant="outlined"
          >
            Verify and Pay
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      wrap="wrap"
    >
     <h4>Loading...</h4> 
    </Grid>
  );
}
