import React, { useState, useEffect } from "react";
import CurrencyExchangeOutlined from "@mui/icons-material/CurrencyExchangeOutlined";
import AutoCompletion from "../../components/autoComplete";
import Dialog from "../../components/dialog";
import FormPayment from "../../components/formPayment";
import {
  Grid,
  Card,
  TextField,
  Typography,
  Button,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";

export default function Add({
  data,
  amount,
  setAmount,
  setAccount,
  account,
  handleComplete,
  listMethodPayment,
  methodPayment,
  handleChangeMethodPayment,
  handleFormPayment,
  payment,
  handleSentPaymentForm,
}) {
  const [open, setOpen] = useState(false);

  const message = "Add money";
  const handleClose = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    if (amount < 9 && account.currency.code == "HTG") {
      toast("amount should 10 or more");
      return;
    }

    if (amount < 10 && account.currency.code == "USD") {
      toast("amount should 10 or more");
      return;
    }
    if (methodPayment === "Moncash") {
      handleComplete();
      return;
    }
    if (methodPayment === "Moncash Manuel" && account.currency.code !== "HTG") {
      toast(
        " Method payment Moncash go only on HTG account Please select HTG account"
      );
      return;
    }
    if (account && amount && methodPayment) {
      handleClose();
      return;
    }
    toast("select method payment, account and amount > 1500");
  };

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        sx={{ padding: "20%, 10%" }}
      >
        <Dialog
          Content={
            <FormPayment
              name={methodPayment}
              payment={payment}
              handleFormPayment={handleFormPayment}
              handleSentPaymentForm={handleSentPaymentForm}
              amount={amount}
            />
          }
          open={open}
          handleClose={handleClose}
          message={message}
        />
        <Typography
          align="center"
          color="text.secondary"
          sx={{
            lineHeight: "32px",
          }}
          variant="p"
        >
          Add money
        </Typography>
        <Grid container mt={3} spacing={{ md: 3, xs: 1 }}>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              name="methodPayment"
              value={methodPayment}
              onChange={handleChangeMethodPayment}
              select
              label="Select Payment Method"
              placeholder="Sogebank"
            >
              <MenuItem value="">...</MenuItem>
              {listMethodPayment.map((value, key) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}></Grid>

          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <AutoCompletion
              placeholder="Select Account"
              value={account}
              handleChange={(value) => setAccount(value)}
              data={data}
              show
            />
          </Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {account ? account.currency?.code : "$"}
                  </InputAdornment>
                ) , min : 1
              }}
              fullWidth
              name="amount"
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value.replace(/-/g, '')))}
            />
          </Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <Button
              onClick={handleClick}
              startIcon={<CurrencyExchangeOutlined />}
              fullWidth
              variant="outlined"
            >
              Payment
            </Button>
          </Grid>
          <Grid item md={4} xs={12}></Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
