import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { loginGoogle } from "../store/reducers/userReducer";

export const useScript = (url, onLoad) => {
  useEffect(() => {
    const existingScript = document.querySelector(`script[src="${url}"]`);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = url;
      script.onload = onLoad;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    } else {
      existingScript.addEventListener("load", onLoad);
      return () => existingScript.removeEventListener("load", onLoad);
    }
  }, [url]); // Removed onLoad from dependencies to avoid unnecessary reloads
};

export default function GoogleLogin({ text = "signin_with" }) {
  const dispatch = useDispatch();

  const onGoogleSignIn = async (response) => {
    console.log(response);
    await dispatch(loginGoogle({ token: response.credential }));
  };

  const googleSignInButton = useRef(null);

  useScript("https://accounts.google.com/gsi/client", () => {
    if (!window.google) {
      console.error("Google script not loaded");
      return;
    }

    window.google.accounts.id.initialize({
      client_id:
        "390399489806-f2rqnpb9k1usdgijhvia801mkq1s031q.apps.googleusercontent.com", // Make sure to use your actual client ID
      callback: onGoogleSignIn,
    });

    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      {
        //theme: "filled_blue",
        size: "large",
        Forme: "rectangular",
        text,
        logo_alignment: "center",
      } // Ensure customization attributes are valid
    );
  });

  return <div ref={googleSignInButton} />;
}
