// src/components/Testimonials.js
import React from 'react';
import { Grid, Paper, Typography, Box, Avatar } from '@mui/material';
import { styled } from '@mui/system';

const testimonials = [
  {
    name: 'Jean D.',
    quote: 'SpeedyTransfer m\'a simplifié la vie. Plus besoin de faire la queue à la banque !',
    avatar: 'https://i.pravatar.cc/150?img=3'
  },
  {
    name: 'Marie P.',
    quote: 'Comment SpeedyTransfer a aidé Marie à envoyer de l\'argent à sa famille.',
    avatar: 'https://i.pravatar.cc/150?img=5'
  },
  {
    name: 'Paul R.',
    quote: 'Un service rapide et fiable. Je recommande SpeedyTransfer à tout le monde.',
    avatar: 'https://i.pravatar.cc/150?img=6'
  },
  {
    name: 'Anaïs B.',
    quote: 'L\'application est super facile à utiliser et très pratique pour mes besoins quotidiens.',
    avatar: 'https://i.pravatar.cc/150?img=7'
  },
  {
    name: 'Luc M.',
    quote: 'Le meilleur service de transfert d\'argent que j\'ai utilisé jusqu\'à présent.',
    avatar: 'https://i.pravatar.cc/150?img=8'
  },
  {
    name: 'Elodie T.',
    quote: 'Excellent service client et des transferts rapides. Très satisfaite !',
    avatar: 'https://i.pravatar.cc/150?img=9'
  }
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Testimonials() {
  return (
    <Box sx={{ pt: 8, pb: 6 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Témoignages
      </Typography>
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <StyledPaper>
              <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ width: 56, height: 56, margin: '0 auto' }} />
              <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                {testimonial.name}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1, fontStyle: 'italic' }}>
                "{testimonial.quote}"
              </Typography>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Testimonials;
