import React from "react";
import { Layout } from "../layouts/dashboard/layout";
import Index from "../pages-sections/account";

export default function Account() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Index />
    </Layout>
  );
}
