import { useCallback, useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { toast } from "react-toastify";

import { postData } from "../../fetch/post";
import useSessionStorage from "../../hooks/useSessionStorage";

export const AccountProfileDetails = () => {
  const user = useSessionStorage("user");

  const [values, setValues] = useState({
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
  });

  const handleChange = useCallback(async (event) => {
    const name = event.target.name;
    setValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  }, []);

  useEffect(() => {
    setValues({
      firstName: user?.first_name,
      lastName: user?.last_name,
      email: user?.email,
    })
  }, [user])
  

  const handleSubmit = () => {};

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  helperText="Please specify the first name"
                  label="First name"
                  name="firstName"
                  onChange={handleChange}
                  required
                  value={values.firstName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  onChange={handleChange}
                  required
                  value={values.lastName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained">Save details</Button>
        </CardActions>
      </Card>
    </form>
  );
};
