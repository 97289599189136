import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { AccountProfile } from "./account-profile";
import { AccountProfileDetails } from "./account-profile-details";
import { AccountPassword } from "./account-password";
import useSessionStorage from "../../hooks/useSessionStorage";
import Kyc from "./kyc";

const Page = () => {
  const user = useSessionStorage("user");

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Account</Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12} md={6} lg={4}>
                  <AccountProfile user={user} />
                </Grid>
                <Grid xs={12} md={6} lg={8}>
                  <AccountProfileDetails user={user} />
                </Grid>

                <Grid xs={12} md={6} lg={12}>
                  <Kyc user={user} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Page;
