import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
// import Wallet from "@heroicons/react/24/solid/WalletIcon";
import { SvgIcon } from "@mui/material";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  //SwapHorizOutlined,
} from "@mui/icons-material";
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import RedeemIcon from '@mui/icons-material/Redeem';
import AddCardIcon from '@mui/icons-material/AddCard';
import SendIcon from '@mui/icons-material/Send';

export const items = [
  {
    title: "Overview",
    path: "/dashboard",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Add Money",
    path: "/transaction/add",
    icon: (
      <SvgIcon fontSize="small">
        <Add />
      </SvgIcon>
    ),
  },
  {
    title: "Send Money",
    path: "/transaction/send",
    icon: (
      <SvgIcon fontSize="small">
        <SendIcon />
      </SvgIcon>
    ),
  },
  {
    title: "WithDraw",
    path: "/transaction/withdraw",
    icon: (
      <SvgIcon fontSize="small">
        <ArrowDownward />
      </SvgIcon>
    ),
  },
  {
    title: "Topup",
    path: "/topup",
    icon: (
      <SvgIcon fontSize="small">
        <SettingsPhoneIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Gift Card",
    path: "/gift_card",
    icon: (
      <SvgIcon fontSize="small">
        <RedeemIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Card",
    path: "/card",
    icon: (
      <SvgIcon fontSize="small">
        <AddCardIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Family and Friends",
    path: "/customers",
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Account",
    path: "/account",
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Manage withdraw",
    path: "/settings",
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    ),
  },
];
