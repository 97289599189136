import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Backdrop,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import registerSchema from "../yup/RegisterSchema";
import Google from "../components/GoogleLogin";

import { register } from "../store/reducers/userReducer";

import Alert from "../components/alert";

export default function RegisterForm() {
  const loginUser = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState("error");
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      last_name: "",
      first_name: "",
      password: "",
      re_password: "",
    },
    validationSchema: registerSchema,
    onSubmit: async (values, helpers) => {
      setOpen(true);
      await dispatch(register(values));
      setOpenAlert(true);
      setOpen(false);
      helpers.resetForm();
      // navigate("/");
      return;
    },
  });

  useEffect(() => {
    if (loginUser.isLoggedIn) {
      navigate("/dashboard");
      return;
    }
  }, [loginUser.isLoggedIn]);

  return (
    <>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <div>
            <Stack spacing={3}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <Alert
                open={openAlert}
                message={loginUser.message}
                color={loginUser.color}
                setOpen={setOpenAlert}
              />
            </Stack>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">Register</Typography>
              <Typography color="text.secondary" variant="body2">
                Already have an account? &nbsp;
                <Link
                  to="/login"
                  style={{ textDecoration: "none" }}
                  underline="hover"
                  variant="subtitle2"
                >
                  Log in
                </Link>
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack direction="row" mb={3} spacing={3}>
                <TextField
                  error={
                    !!(formik.touched.first_name && formik.errors.first_name)
                  }
                  fullWidth
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                  label="First Name"
                  name="first_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
                <TextField
                  error={
                    !!(formik.touched.last_name && formik.errors.last_name)
                  }
                  fullWidth
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                  label="Last Name"
                  name="last_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                />
              </Stack>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
                <TextField
                  error={
                    !!(formik.touched.re_password && formik.errors.re_password)
                  }
                  fullWidth
                  helperText={
                    formik.touched.re_password && formik.errors.re_password
                  }
                  label="Retype Password"
                  name="re_password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.re_password}
                />
              </Stack>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Continue
              </Button>
            </form>
          </div>
          <Stack mt={3} spacing={3}>
            <Divider />
            <Google />
          </Stack>
        </Box>
      </Box>
    </>
  );
}
